import React from 'react';
import { styled } from "../../../stitches.config";
import Box from '../Box'
import { useWindowWidth } from "../../../helpers";

const Wrapper = styled("div", {
	/* dodatkowe style do embedów które zmieniają się w photoOnTop na mobile */
	".photo-on-top-embed": {
		padding: "0 12px"
	},
	variants: {
		wrapper: {
			true: {
				padding: "48px 0 40px",
			},
			false: {}
		}
	},
	defaultVariants: {
		wrapper: true
	}
});

export default props => {
	const isPlaceholder = !!window.APP_SETTINGS?.placeholder;
	const windowWidth = useWindowWidth();

	return (
		<>
			{windowWidth > 996 ?
				<Wrapper data-tb-region={props.blockSettings.tb_region} wrapper={!props.blockSettings.withoutWrapper}>
					<Box {...props}
						id="b1"
						type={"BTextOnPicture"}
						settings={props.settings.b1}
						imageHeight={600}
						size="lg"
						classes="" />
				</Wrapper>
				:
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<Wrapper data-tb-region={props.blockSettings.tb_region} wrapper={!props.blockSettings.withoutWrapper}>
								<Box {...props}
									id="b1"
									type={"BPhotoOnTop"}
									settings={props.settings.b1}
									imageHeight={""}
									size="lg"
									classes="" />
							</Wrapper>
						</div>
					</div>
				</div>
			}
		</>
	);
}
